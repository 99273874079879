import lightstory1 from './image/lightstory_1.png';
import lightstory2 from './image/lightstory_2.png';
import lightstory3 from './image/lightstory_3.png';
import lightstory4 from './image/lightstory_4.png';
import lightstory5 from './image/lightstory_5.png';
import lightstory6 from './image/lightstory_6.png';
import lightstory7 from './image/lightstory_7.png';
import lightstory8 from './image/lightstory_8.png';
import lightstory9 from './image/lightstory_9.png';
import lightstory10 from './image/lightstory_10.png';

const ShowcaseImg = [lightstory1, lightstory2, lightstory3, lightstory4, lightstory5, lightstory6, lightstory7, lightstory8, lightstory9, lightstory10];

export default ShowcaseImg;

import bumpToBaby1 from './image/bump_to_baby1.png';
import bumpToBaby2 from './image/bump_to_baby2.png';
import bumpToBaby3 from './image/bump_to_baby3.png';
import bumpToBaby4 from './image/bump_to_baby4.png';
import bumpToBaby5 from './image/bump_to_baby5.png';
import bumpToBaby6 from './image/bump_to_baby6.png';
import bumpToBaby7 from './image/bump_to_baby7.png';
import bumpToBaby8 from './image/bump_to_baby8.png';
import bumpToBaby9 from './image/bump_to_baby9.png';

const BumpToBabyImages = [bumpToBaby1, bumpToBaby2, bumpToBaby3, bumpToBaby4, bumpToBaby5, bumpToBaby6, bumpToBaby7, bumpToBaby8, bumpToBaby9];

export default BumpToBabyImages;

import award1 from './image/1.png';
import award2 from './image/2.png';
import award3 from './image/3.png';
import award4 from './image/4.png';
import award5 from './image/5.png';
import award6 from './image/6.png';

const AwardsImg = [award1, award2, award3, award4, award5, award6];

export default AwardsImg;

import outdoor1 from './image/outdoor1.png';
import outdoor2 from './image/outdoor2.png';
import outdoor3 from './image/outdoor3.png';
import outdoor4 from './image/outdoor4.png'; import outdoor5 from './image/outdoor5.png';
import outdoor6 from './image/outdoor6.png';
import outdoor7 from './image/outdoor7.png';
import outdoor8 from './image/outdoor8.png';
import outdoor9 from './image/outdoor9.png';
import outdoor10 from './image/outdoor10.png';
import outdoor11 from './image/outdoor11.png'; import outdoor12 from './image/outdoor12.png';

const OutdoorImages = [outdoor1, outdoor2, outdoor3, outdoor4, outdoor5, outdoor6, outdoor7, outdoor8, outdoor9, outdoor10, outdoor11, outdoor12];

export default OutdoorImages;

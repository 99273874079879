import fineArt1 from './image/fine_art1.png';
import fineArt2 from './image/fine_art2.png';
import fineArt3 from './image/fine_art3.png';
import fineArt4 from './image/fine_art4.png';
import fineArt5 from './image/fine_art5.png';
import fineArt6 from './image/fine_art6.png';
import fineArt7 from './image/fine_art7.png';
import fineArt8 from './image/fine_art8.png';

const FineArtImages = [fineArt1, fineArt2, fineArt3, fineArt4, fineArt5, fineArt6, fineArt7, fineArt8];

export default FineArtImages;
